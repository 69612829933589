/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
import { graphql } from "gatsby";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type { PageQuery } from "../../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEventImage" }>;
  data: PageQuery["event"];
}

const EventImage: React.FC<Props> = ({
  data,
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  if (!data?.poster) {
    return null;
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <CDNImage
        src={data?.poster}
        sx={{ margin: "0 auto" }}
        alt={data.title}
        width={data.posterProbe?.width}
        height={data.posterProbe?.height}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetEventImage on WidgetEventImage {
    id
    __typename
  }
`;

export default memo(EventImage);
